import store from "@/store";
import {getCookie} from "@/helpers/RouteAccessChecksHelper";

function isVerificationComplete(user) {
  if (store.getters.getSessionAuthenticationMethod === "email") {
    return user.emailVerified;
  }
  if (store.getters.getSessionAuthenticationMethod === "phone") {
    return user.phoneVerified;
  }
  // TODO: Handle OAuth Cases
  return true;
}

function getUserOnboardingValues() {
  const user = store.getters.getUser;
  const selectedPlan = getCookie("selected-plan");
  const skipVerification = getCookie("skip-verification");
  const selectedCountry = getCookie("plan-country");

  return {user, selectedPlan, skipVerification, selectedCountry};
}

export default [
  /** ****************************************************************************************************
     * UNAUTHENTICATED ROUTES
     ******************************************************************************************************/
  {
    path: "/purchase/starter",
    beforeEnter: async (to, from, next) => {
      const query = {selectedPlan: "starter"};

      if (from.query.coupon) query.coupon = from.query.coupon;

      return next({
        path: "/signup/create-account",
        query,
      });
    },
  },
  {
    path: "/purchase/commit",
    beforeEnter: async (to, from, next) => {
      const query = {selectedPlan: "power"};

      if (from.query.coupon) query.coupon = from.query.coupon;

      return next({
        path: "/signup/create-account",
        query,
      });
    },
  },
  {
    path: "/signup/redeem",
    redirect: "/home"
  },
  /**
     * Onboarding Routes
     */
  {
    path: "/onboarding",
    name: "onboarding",
    meta: {
      onboarding: true,
      tracking: {
        sections: ["onboarding"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/OnboardingPage.vue"
      ),
    children: [
      {
        path: "verification",
        meta: {
          onboarding: true,
        },
        component: () => import(/* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/components/steps/AccountVerificationStep.vue"),
        name: "accountVerificationStep",
        beforeEnter: async (to, from, next) => {
          const {user, selectedPlan, skipVerification, selectedCountry} = getUserOnboardingValues();

          const appConfig = store.getters.getAppConfiguration;

          if (!appConfig.defaultTermsConsent && !store.getters.hasSubmittedRequiredConsents) {
            return next({name: "consent"})
          } else if (selectedPlan && isVerificationComplete(user)) {
            return next({name: "checkoutStep", params: {plan: selectedPlan, country: selectedCountry}});
          } else if (!selectedPlan && (isVerificationComplete(user) || skipVerification)) {
            return next({name: "redeemLicenseStep"});
          } else return next();
        }
      },
      {
        path: "redeem-license",
        meta: {
          onboarding: true,
        },
        component: () => import(/* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/components/steps/RedeemLicenseStep.vue"),
        name: "redeemLicenseStep",
        beforeEnter: async (to, from, next) => {
          const user = store.getters.getUser;

          if (user.app.accountType !== "free")
            return next({name: "consentsStep"});

          return next();
        }
      },
      {
        path: "checkout/:country/:plan",
        meta: {
          onboarding: true,
        },
        component: () => import(/* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/components/steps/CheckoutStep.vue"),
        name: "checkoutStep",
      },
      {
        path: "consent",
        meta: {
          onboarding: true,
        },
        component: () => import(/* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/components/steps/AdditionalConsentsStep.vue"),
        name: "consentsStep",
        beforeEnter: async (to, from, next) => {

          if (store.getters.hasSubmittedRequiredConsents)
            return next({name: "initialProfileStep"});
          else return next();
        }
      },
      {
        path: "profile",
        meta: {
          onboarding: true,
        },
        component: () => import(/* webpackChunkName: "onboarding" */ "@/modules/Onboarding/pages/OnboardingPage/components/steps/InitialProfileStep.vue"),
        name: "initialProfileStep",
        beforeEnter: async (to, from, next) => {
          if (store.getters.isInitialProfileComplete)
            return next({name: "profileHomePage"});

          return next();
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      const user = store.getters.getUser;
      const selectedPlan = getCookie("selected-plan");
      const skipVerification = getCookie("skip-verification");
      const selectedCountry = getCookie("plan-country");

      // If they have completed initial onboarding
      if (await store.getters.appStage !== "onboarding") {
        return next({name: "profileHomePage"});
      }

      // If consent to terms is not granted by default on signup - all users are forced to the consent step
      if (!store.getters.hasConsented)
        if (to.name !== "consentsStep")
          return next({name: "consentsStep"});
        else return next();

      // License Key Flow
      if (!selectedPlan)
        if (!isVerificationComplete(user) && !skipVerification)
          if (to.name !== "accountVerificationStep")
            return next({name: "accountVerificationStep"});
          else return next();
        else if (user.app.accountType === "free")
          if (to.name !== "redeemLicenseStep")
            return next({name: "redeemLicenseStep"});
          else return next();
        else if (!store.getters.hasSubmittedRequiredConsents)
          if (to.name !== "consentsStep")
            return next({name: "consentsStep"});
          else return next();
        else if (!store.getters.isInitialProfileComplete)
          if (to.name !== "initialProfileStep")
            return next({name: "initialProfileStep"});
          else return next();

      if (selectedPlan)
        if (!isVerificationComplete(user))
          if (to.name !== "accountVerificationStep")
            return next({name: "accountVerificationStep"});
          else return next();
        else if (user.app.accountType === "free")
          if (to.name !== "checkoutStep")
            return next({name: "checkoutStep", params: {plan: selectedPlan, country: selectedCountry}});
          else return next();
        else if (!store.getters.hasSubmittedRequiredConsents)
          if (to.name !== "consentsStep")
            return next({name: "consentsStep"});
          else return next();
        else if (!store.getters.isInitialProfileComplete)
          if (to.name !== "initialProfileStep")
            return next({name: "initialProfileStep"});
          else return next();
    },
  },
];
