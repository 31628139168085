<script>
export default {
  name: "ContentCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Let's Go",
    },
    buttonIcon: {
      type: String,
      default: "fa-chevron-circle-right",
    },
    allowSaving: {
      type: Boolean,
      default: false,
    },
    isSaved: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Number,
      default: null
    }
  },
};
</script>

<template>
  <v-card
    data-cy="contentCard"
    class="align-end content-card"
    :class="variant && !image ? `cta-image-card variant-${variant}` : ''"
    height="450px"
    color="primary"
  >
    <v-img
      class="white--text align-end"
      height="450px"
      :src="image ? `${image}` : ''"
      :gradient="
        image
          ? `to top, ${$vuetify.theme.themes.light['primary']}99, ${$vuetify.theme.themes.light['primary']}99`
          : ''
      "
    >
      <v-row align-content="center" class="top-row pt-4">
        <v-col v-if="logo">
          <div class="logo-circle-image white elevation-12 ml-6 mt-3">
            <img width="80px" height="auto" :src="logo" alt="">
          </div>
        </v-col>
        <v-spacer />
        <v-col align-self="center" class="text-right">
          <slot name="chips" />
        </v-col>
      </v-row>
      <v-card-title
        class="font-weight-bold content-card-title text-on-color--text"
        @click="$emit('click')"
      >
        {{ title }}
      </v-card-title>
      <v-card-subtitle class=" text-on-color--text">
        {{ subTitle }}
      </v-card-subtitle>
      <v-card-actions class="pb-5 px-5">
        <v-btn
          v-if="allowSaving"
          icon
          data-cy="favouritesButton"
          @click="$emit('save')"
        >
          <v-icon v-if="!isSaved && !isSaving" color="icon-on-color">
            fa-regular fa-heart
          </v-icon>
          <v-icon v-if="isSaved && !isSaving" color="red">
            fa-heart
          </v-icon>
          <v-progress-circular
            v-if="isSaving"
            class="v-progress-circular--visible"
            indeterminate
            :size="20"
            :width="3"
            color="green"
          />
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="showButton"
          data-cy="openContentButton"
          elevation="0"
          color="button-on-color primary--text"
          @click="$emit('click')"
        >
          {{ buttonText }}
          <v-icon v-if="buttonIcon" right>
            {{ buttonIcon }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>

<style scoped>
.top-row {
  position: absolute;
  top: 0;
  width: 100%;
}

.content-card-title {
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: break-word;
}
</style>
