<script>
import ProgressBar from "@/modules/OccupationsNavigator/components/ProgressBar.vue";

export default {
  name: "ProgressBarsThemeSection",
  components: { ProgressBar },
};
</script>

<template>
  <v-container
    id="nav"
    fluid
  >
    <v-row>
      <v-col>
        <h1>Components</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Measurement Bars</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ProgressBar :progress="50" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Loading Bars</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear indeterminate />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-circular color="primary" indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
