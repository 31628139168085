<script>
import CTAThemeSection from "@/modules/Theme/components/CTAThemeSection.vue";
import PageCoverThemeSection from "@/modules/Theme/components/PageCoverThemeSection.vue";
import CardsThemeSection from "@/modules/Theme/components/CardsThemeSection.vue";
import ColorsThemeSection from "@/modules/Theme/components/ColorsThemeSection.vue";
import NavBarThemeSection from "@/modules/Theme/components/NavBarThemeSection.vue";
import ButtonsThemeSection from "@/modules/Theme/components/ButtonsThemeSection.vue";
import ProgressBarsThemeSection from "@/modules/Theme/components/ProgressBarsThemeSection.vue";
import ChipsThemeSection from "@/modules/Theme/components/ChipsThemeSection.vue";
import ExpansionPanelsThemeSection from "@/modules/Theme/components/ExpansionPanelsThemeSection.vue";
import TypographyThemeSection from "@/modules/Theme/components/TypographyThemeSection.vue";

export default {
  name: "ThemePage",
  components: {
    TypographyThemeSection,
    ExpansionPanelsThemeSection,
    ChipsThemeSection,
    ProgressBarsThemeSection,
    ButtonsThemeSection,
    NavBarThemeSection,
    ColorsThemeSection,
    CardsThemeSection,
    PageCoverThemeSection,
    CTAThemeSection,
  },
  data() {
    return {
      section: "all",
    };
  },
  computed: {
    currentSection() {
      if (this.$route.params.section) return this.$route.params.section;

      return "all";
    },
  },
  methods: {
    gotToSection(section) {
      if (section !== this.currentSection)
        this.$router.push({ name: "themeSettingsPage", params: { section } });
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-list>
          <v-list-item class="font-weight-bold">
            Components
          </v-list-item>
          <v-divider />
          <v-list-item @click="gotToSection('all')">
            All
          </v-list-item>
          <v-list-item @click="gotToSection('colors')">
            Colors
          </v-list-item>
          <v-list-item @click="gotToSection('nav')">
            Navigation
          </v-list-item>
          <v-list-item @click="gotToSection('buttons')">
            Buttons
          </v-list-item>
          <v-list-item @click="gotToSection('cta')">
            CTAS
          </v-list-item>
          <v-list-item @click="gotToSection('pageCovers')">
            Page Cover
          </v-list-item>
          <v-list-item @click="gotToSection('cards')">
            Cards
          </v-list-item>
          <v-list-item @click="gotToSection('bars')">
            Progress Bars
          </v-list-item>
          <v-list-item @click="gotToSection('chips')">
            Chips
          </v-list-item>
          <v-list-item @click="gotToSection('accordion')">
            Expansion panels
          </v-list-item>
          <v-list-item @click="gotToSection('text')">
            Text and Typography
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="9">
        <ColorsThemeSection
          v-if="currentSection === 'colors' || currentSection === 'all'"
        />

        <CTAThemeSection
          v-if="currentSection === 'cta' || currentSection === 'all'"
          id="nav"
        />

        <PageCoverThemeSection
          v-if="currentSection === 'pageCovers' || currentSection === 'all'"
        />

        <CardsThemeSection
          v-if="currentSection === 'cards' || currentSection === 'all'"
        />

        <NavBarThemeSection
          v-if="currentSection === 'nav' || currentSection === 'all'"
        />

        <ButtonsThemeSection
          v-if="currentSection === 'buttons' || currentSection === 'all'"
        />

        <ProgressBarsThemeSection
          v-if="currentSection === 'bars' || currentSection === 'all'"
        />

        <ChipsThemeSection
          v-if="currentSection === 'chips' || currentSection === 'all'"
        />

        <ExpansionPanelsThemeSection
          v-if="currentSection === 'accordion' || currentSection === 'all'"
        />

        <TypographyThemeSection v-if="currentSection === 'text' || currentSection === 'all'" />
      </v-col>
    </v-row>
  </v-container>
</template>

