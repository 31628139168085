import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  // UPLIFTMENT PROVIDER EXPLORER
  {
    path: "/navigators/upliftment-providers",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/UpliftmentProvider/pages/UpliftmentProviderExplorerPage/UpliftmentProviderExplorerPage.vue"
      ),
    name: "upliftmentProvidersNavigator",
    meta: {
      feature: Feature.UpliftmentProviderExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "upliftment providers"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.UpliftmentProviderExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true
  },

  // UPLIFTMENT PROVIDER DETAIL
  {
    path: "/navigators/upliftment-providers/:slug/:id",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/UpliftmentProvider/pages/UpliftmentProviderDetailPage/UpliftmentProviderDetailPage.vue"
      ),
    name: "upliftmentProviderDetailPage",
    meta: {
      feature: Feature.UpliftmentProviderExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "upliftment providers", "provider"],
      }
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.UpliftmentProviderExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true
  },
];
