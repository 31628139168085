import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.js$/i);

  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = {...messages[locale], ...locales(key).default};
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages: loadLocaleMessages()
});

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export default i18n;
