// router.js
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// Module Routers
import authenticationRoutes from "@/modules/Authentication/router";
import settingsRoutes from "@/modules/Settings/router";
import profile from "@/modules/Profile/router";
import shopRoutes from "@/modules/Onboarding/router";
import profileRoutes from "@/modules/CV/router";
import reportRoutes from "@/modules/Reports/router";
import consentRoutes from "@/modules/Policies/router";
import upliftmentProviderRoutes from "@/modules/UpliftmentProvider/router";
import researchRoutes from "@/modules/AssessmentResearch/router";
import resourcesNavigatorRoutes from "@/modules/ResourcesNavigator/router";
import fundingNavigatorRoutes from "@/modules/FundingNavigator/router";
import tertiaryNavigatorRoutes from "@/modules/TertiaryNavigator/router";
import skillsNavigatorRoutes from "@/modules/SkillsetsNavigator/router";
import occupationsNavigatorRoutes from "@/modules/OccupationsNavigator/router";
import subjectChoiceNavigatorRoutes from "@/modules/SubjectChoiceNavigator/router";

// Components
import NotFoundPage from "@/components/NotFoundPage";
import ErrorPage from "@/components/ErrorPage";

import analyticsEvents from "@/library/analyticsEvents.lib";
import NotAllowedPage from "@/components/NotAllowedPage.vue";
import ThemePage from "@/modules/Theme/ThemePage.vue";

if (process.env.NODE_ENV !== "test") {
  Vue.use(VueRouter);
}

/**
 * Setup routers
 */
const baseRoutes = [
  {
    path: "/error/404",
    component: NotFoundPage,
    name: "404",
    meta: {
      ignoreAuth: true,
    },
  },
  {
    path: "/error/not-allowed",
    component: NotAllowedPage,
    name: "403",
    meta: {
      ignoreAuth: true,
    },
  },
  {
    path: "/error/whoops",
    component: ErrorPage,
  },
  {
    path: "/theme/:section?",
    name: "themeSettingsPage",
    component: ThemePage,
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/signup/create-account",
    redirect: "/home",
  },
  {
    path: "*",
    redirect: "/error/404",
  },
];

const routes = baseRoutes.concat(
  profile,
  authenticationRoutes,
  consentRoutes,
  profileRoutes,
  shopRoutes,
  settingsRoutes,
  upliftmentProviderRoutes,
  researchRoutes,
  resourcesNavigatorRoutes,
  fundingNavigatorRoutes,
  tertiaryNavigatorRoutes,
  skillsNavigatorRoutes,
  occupationsNavigatorRoutes,
  subjectChoiceNavigatorRoutes,
  reportRoutes
);

/**
 * Setup the Router
 * @type {VueRouter}
 */
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authenticatedWhitelist = ["/settings", "/logout", "/research"];

  // Shows a loading bar in between route change. Useful when route change requires a new JS chunk to be loaded.
  await store.dispatch("setLoading", true);

  // If trying to logout, skip all checks
  if (to.name === "logout") return next();

  // If this is the first time the store is loaded, try fetch the user
  if (!store.state.initialised) await store.dispatch("fetchUser");

  // If the store is loaded and there is a valid user
  if (store.state.initialised && store.state.user) {
    const appStage = store.getters.appStage;

    if (authenticatedWhitelist.includes(to.path)) {
      return next();
    }

    // If they are accessing a route that requires them to be logged OUT
    if (to.meta.isLoggedOut) {
      await router.push({ name: "profileHomePage" });
    }

    // ONBOARDING: User needs to be onboarded
    if (appStage === "onboarding" && !to.meta.onboarding) {
      return router.push({
        name: "onboarding",
        params: { step: "verification" },
        query: to.query,
      });
    }

    // RESEARCH: User has a research requirement they have not completed
    if (appStage === "research" && to.meta.appStage !== "research") {
      return router.push({
        name: "researchDashboard",
      });
    }

    // RESEARCH: If the user is a research using and trying to access research dashboard
    if (to.name === "researchDashboard" && !store.getters.isResearchUser) {
      return next();
    }

    // WORKERTYPE: User has not taken the default worker type assessment
    if (appStage === "workerType" && to.meta.appStage !== "workerType") {
      return router.push({
        name: "hollandCodeReportPage",
      });
    }

    // if they are logged out and trying to access a page that requires them to be logged in
  } else if (!to.meta.isLoggedOut && !to.meta.ignoreAuth) {
    const query = {};

    // Adds a redirect to go to post login
    if (window.location.pathname !== "/")
      query.redirect = window.location.pathname;

    if (to.query.code) query.code = to.query.code;

    if (to.query.coupon) query.coupon = to.query.coupon;

    await router.push({ name: "loginPage", query });
  }

  return next();
});
router.afterEach((to, from) => {
  // Track when the user leaves the page
  if (from && from.name && from.meta.breadcrumbs) {
    const fromPageName = from.meta.breadcrumbs[from.meta.breadcrumbs.length]
      ? from.meta.breadcrumbs[from.meta.breadcrumbs.length].title
      : from.name;
    analyticsEvents.exitedPage(
      fromPageName,
      from.name,
      from.params,
      from.meta.isLoggedOut
    );
  }

  // Track when a page is opened
  // if there is a tracking object defined in the route
  // and if the tracking is not supposed to occur "in the page"
  if (to.meta.tracking && !to.meta.tracking.inPage) {
    analyticsEvents.pageView(to);
  }

  // Hides a loading bar after route change.
  store.dispatch("setLoading", false);

  window.scrollTo(0, 0);
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
