<template>
  <div>
    <v-select
      v-show="showDropdown"
      v-model="language"
      class="language-selector caption primary"
      outlined
      flat
      dense
      hide-details
      single-line
      solo
      full-width
      :items="languages"
      @change="changeLanguage"
    >
      <template #item="{ item }">
        {{ labels[item] }}
      </template>
      <template #selection="{ item }">
        {{ labels[item] }}
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      language: this.$root.$i18n.locale,
      labels: {
        en: "English",
        en_ZA: "English",
        am: "Amharic",
        af: "Afrikaans",
        ar: "Arabic",
        om: "Oromo",
      },
    };
  },
  computed: {
    showDropdown() {
      return this.appConfiguration.locales.available.length > 1;
    },
    languages() {
      return this.appConfiguration.locales.available;
    },
  },
  mounted() {
    const storedLanguage = localStorage.getItem("language");

    if (storedLanguage) this.language = storedLanguage;
  },
  methods: {
    changeLanguage() {
      this.$root.$i18n.locale = this.language;
      localStorage.setItem("language", this.language);
    },
  },
};
</script>

<style scoped>
.language-selector::v-deep input {
  display: none;
}
</style>
