import LoadScript from "vue-plugin-load-script";
import VueConfetti from "vue-confetti";
import VueMeta from "vue-meta";
import BlockContent from "sanity-blocks-vue-component";
import VueSanitize from "vue-sanitize";
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import VueGtag from "vue-gtag";
import mixpanel from "mixpanel-browser";
import CrispChat from "@dansmaculotte/vue-crisp-chat";
import analyticsEvents from "@/library/analyticsEvents.lib";
import VueGtm from "@gtm-support/vue2-gtm";
import router from "@/router/router";
import environmentConfig from "@/config/environment.config";

// Current App Version
const VERSION = require("../../package.json").version;

/**
 * Vue configuration
 * @param Vue
 */
function config(Vue) {
  Vue.config.productionTip = false;
  Vue.config.logLevel = "debug";
  Vue.config.errorHandler = (err, vm, info) => {
    // eslint-disable-next-line no-console
    console.error(err);
    // eslint-disable-next-line no-console
    console.error(vm);
    // eslint-disable-next-line no-console
    console.error(info);
  };
}

/**
 * Tools for the app
 * @param Vue
 */
function tools(Vue) {
  // Tool to inject external scripts into Vue
  Vue.use(LoadScript);

  // Adds a sanitiser for WYSIWYG content
  const sanitiserOptions = {
    allowedTags: ["b", "p", "strong", "i", "u", "s", "ul", "li", "ol"],
  };
  Vue.use(VueSanitize, sanitiserOptions);
}

/**
 * Vue Plugins
 * @param Vue
 */
function plugins(Vue) {
  // Confetti for the assessment success pages
  Vue.use(VueConfetti);
  // Adds meta data
  Vue.use(VueMeta);
  // Add the Sanity Block-Content component
  Vue.component("BlockContent", BlockContent);
}

/**
 * Logging tools for audit and error monitoring
 * @param Vue
 */
function logging(Vue, config, instanceKey) {
  if (
    config.sentryDSNUrl &&
    (config.sentryTrackingEnvironment === "production" ||
      config.sentryTrackingEnvironment === "staging")
  )
    Sentry.init({
      Vue,
      dsn: config.sentryDSNUrl,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [environmentConfig.serverUrl],
        }),
        new CaptureConsoleIntegration({
          levels: ["error"],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 0.1,
      environment: `${instanceKey}-${config.sentryTrackingEnvironment}`,
      release: "yenza-webapp@" + VERSION,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    });
}

/**
 * Analytics and tracking libraries
 * @param Vue
 * @returns {Promise<void>}
 */
async function analytics(Vue, config) {
  // Checks the device type of the user
  const deviceCheck = /Mobile/i.test(navigator.userAgent)
    ? "Mobile"
    : "Desktop";

  // Creates a global instance of the analytics and events libraries
  Vue.prototype.$analyticsEvents = analyticsEvents;

  // Load Google Analytics, if it's available in this environment
  if (config.googleAnalyticsCode)
    Vue.use(
      VueGtag,
      {
        globalDataLayerName: "gTagDataLayer",
        config: { id: config.googleAnalyticsCode },
      },
      router
    );

  if (config.googleTagManagerCode) {
    window.dataLayer = [];

    Vue.use(VueGtm, {
      id: config.googleTagManagerCode,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    });
  }

  // Load Mixpanel, if it's available in this environment
  if (config.mixpanelId)
    mixpanel.init(config.mixpanelId);

  // Create the global data layer that will be used by the analytics scripts

  // Load Adobe Analytics, if it's available in this environment
  if (config.adobeAnalyticsLaunchScript) {
    window.dataLayer = {
      loginStatus: "logged out",
      userLoginSuccess: false,
      userRegistrationSuccess: false,
      deviceType: `${deviceCheck}`,
      formIsSubmitted: false,
      formName: "",
      formStatus: "",
      websiteName: `${this.$appName} Platform`,
      websiteNameCode: "PPF",
      siteLanguage: "English",
      pageCategory: "Personal",
      pageName: "loading",
      pageSubSection1: "",
      pageSubSection2: "",
      pageSubSection3: "",
      pageSubSection4: "",

      customerGuid: "",
      email_sha256: "",
    };

    try {
      await Vue.loadScript(config.adobeAnalyticsLaunchScript);
    } catch (e) {
      return e;
    }
  }
}

/**
 * Whitelabel Configurations
 * @param Vue
 */
function whiteLabel(Vue, config) {
  // White Label Configuration
  Vue.prototype.$appName = config.appName | "Yenza Whitelabel";
  Vue.prototype.$appVersion = VERSION;
}

/**
 * Customer support tools
 * @param Vue
 */
function customerSupport(Vue, config) {
  if (config.crispChatId)
    Vue.use(CrispChat, {
      websiteId: config.crispChatId,
      hideOnLoad: true,
    });
}

export default {
  config,
  tools,
  plugins,
  logging,
  analytics,
  whiteLabel,
  customerSupport,
};
