<script>
import CTACard from "@/components/designSystem/CTACard.vue";
import MiniCTACard from "@/components/designSystem/MiniCTACard.vue";

export default {
  name: "CTAThemeSection",
  components: { MiniCTACard, CTACard },
  data: () => ({
    showImage: false,
    variant: 1,
  }),
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>CTAS</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" align-self="center">
        <v-switch v-model="showImage" label="Show Image" hide-details class="mt-0" />
      </v-col>
      <v-col cols="3" align-self="center">
        <v-select
          v-model="variant"
          :disabled="!showImage"
          dense
          hide-details
          :items="[1, 2]"
          label="Variant"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Large CTA</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CTACard
          route-name=""
          icon="fa-compass"
          title="Title"
          sub-title="Sub Title"
          time="5 - x Minutes"
          tag="Tag"
          :show-image="showImage"
          :variant="variant"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Mini CTA</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MiniCTACard
          route-name=""
          :show-image="showImage"
          :variant="variant"
          title="CTA Title"
          sub-title="Subtitle"
          tag="tag"
          icon="fa-compass"
          time="5 - xx Minutes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
