import { cvConfig } from "../config/config";

const availableProfileSections = cvConfig.sections;

const getDefaultState = () => {
  // Reduce the list of assessments into a list of booleans
  const sections = {};

  for (const key in availableProfileSections) {
    sections[key] = false;
  }

  return {
    sections
  };
};

function checkIfProfileSectionComplete(key, getters) {
  const { progress } = availableProfileSections[key];

  if (progress.type === "existence")
    return progress.fields.every(
      (profileKey) =>
        getters.getProfileData(profileKey) !== null && getters.getProfileData(profileKey) !== ""
    );

  if (progress.type === "count") {
    return progress.fields.every(
      (profileKey) => getters.getProfileData(profileKey).length >= progress.amount
    );
  }

  return true;
}

export default {
  state: getDefaultState,
  actions: {
    /**
     * Loops through all the assessments and checks if they are complete
     * @param commit
     * @param userAssessments
     */
    setOverallProfileProgress({ commit, getters }) {
      // Check progress of all sections
      for (const key in availableProfileSections) {
        checkIfProfileSectionComplete(key, getters)
          ? commit("SET_PROFILE_SECTION_COMPLETE", key)
          : commit("SET_PROFILE_SECTION_INCOMPLETE", key);
      }
    }
  },

  getters: {
    getPercentageProfileComplete: (state) => {
      let completeCount = 0;

      for (const profileSection in availableProfileSections) if (state.sections[profileSection]) completeCount++;

      return Math.round((completeCount / Object.keys(state.sections).length) * 100);
    },
    getProfileCompletionCount: (state) => {
      let completeCount = 0;
      let incompleteCount = 0;

      for (const profileSection in availableProfileSections) if (state.sections[profileSection]) completeCount++;
      else incompleteCount++;

      return { completeCount, incompleteCount };
    },
    isProfileSectionComplete: (state) => (profileSection) => state.sections[profileSection]
  },
  mutations: {
    SET_PROFILE_SECTION_INCOMPLETE(state, section) {
      state.sections[section] = false;
    },
    SET_PROFILE_SECTION_COMPLETE(state, section) {
      state.sections[section] = true;
    }
  }
};
