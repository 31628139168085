<script>
import PageCover from "@/components/designSystem/PageCover.vue";

export default {
  name: "PageCoverThemeSection",
  components: { PageCover },
  data: () => ({
    variant: null,
  }),
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Page Covers</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" align-self="center">
        <v-btn @click="variant = null">
          Reset
        </v-btn>
      </v-col>
      <v-col cols="3" align-self="center">
        <v-select
          v-model="variant"
          dense
          hide-details
          :items="[1, 2]"
          label="Variant"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PageCover
          icon="fa-compass"
          title="Title"
          description="Sub Title"
          button="Button Text"
          caption="Caption"
          tag="Tag"
          :variant="variant"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
