import { Feature } from "@YenzaCT/sdk";
import store from "@/store";
import api from "@/library/api.lib";

const checkIfCurriculumSelected = async (to, from, next) => {
  const user = store.getters.getUser;

  const response = await api().get("school-curricula");
  const availableCurriculum = Object.keys(response.data);

  // Checks if the user has selected any curriculum
  // AND if the institution they are in contains the curriculum they selected
  // Otherwise they need to go and choose again
  // This is to accomodate for the potential change of curriculum in an institution AFTER the user has selected a curriculum
  if (
    user &&
    (!user.profile.schoolCurriculum ||
      !availableCurriculum.includes(user.profile.schoolCurriculum))
  ) {
    return next({ name: "subjectChoiceCurriculumSelectionPage" });
  }

  return next();
};

export default [
  /** ====================================================================================================
     * SUBJECT CHOICE
     ====================================================================================================*/
  {
    path: "/navigators/subject-choices/select-curriculum",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceCurriculumSelectionPage/SubjectChoiceCurriculumSelectionPage.vue"
      ),
    name: "subjectChoiceCurriculumSelectionPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices", "select-curriculum"],
      },
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/home",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceHomePage/SubjectChoiceHomePage.vue"
      ),
    name: "subjectChoiceNavigatorHomePage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/subjects",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceNavigatorSubjectsListingPage/SubjectChoiceNavigatorSubjectsListingPage.vue"
      ),
    name: "subjectChoiceNavigatorSubjectsListingPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/careers",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceNavigatorExploreCareersPage/SubjectChoiceNavigatorExploreCareersPage.vue"
      ),
    name: "subjectChoiceCareerListingPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/subjects/:id",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceNavigatorSubjectDetailPage/SubjectChoiceNavigatorSubjectDetailPage.vue"
      ),
    name: "subjectChoiceNavigatorSubjectDetailPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/lessons",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceLessonLibraryPage/SubjectChoiceLessonLibraryPage.vue"
      ),
    name: "subjectChoiceLessonLibraryPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
  {
    path: "/navigators/subject-choices/lessons/:lesson",
    component: () =>
      import(
        /* webpackChunkName: "subjectChoiceNavigator" */ "@/modules/SubjectChoiceNavigator/pages/SubjectChoiceLessonPage/SubjectChoiceLessonPage.vue"
      ),
    name: "subjectChoiceLessonPage",
    meta: {
      feature: Feature.SubjectChoiceExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "subject-choices"],
      },
    },
    beforeEnter: async (to, from, next) => {
      await checkIfCurriculumSelected(to, from, next);
    },
    props: true,
  },
];
