import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  {
    path: "/navigators/resources-navigator",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/ResourcesNavigator/pages/ResourcesNavigatorListingPage/ResourcesNavigatorListingPage.vue"
      ),
    name: "resourcesNavigator",
    meta: {
      feature: Feature.ResourcesExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "resources"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.ResourcesExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
];
