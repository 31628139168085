<script>
export default {
  name: "PageCover",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    caption: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    button: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Number,
      default: null,
    },
  },
};
</script>

<template>
  <div
    :class="
      variant && !image ? `page-cover variant-${variant}` : 'page-cover primary'
    "
  >
    <v-img
      rounded
      class="pa-6 white--text"
      :src="image ? `${image}` : ''"
      :gradient="
        image
          ? `to top, ${$vuetify.theme.themes.light['primary']}99, ${$vuetify.theme.themes.light['primary']}99`
          : ''
      "
      max-height="240px"
    >
      <v-row align-content="center">
        <v-col align-self="center">
          <v-btn
            v-if="showBackButton"
            class="text-on-color--text"
            text
            @click="$emit('back')"
          >
            <v-icon class="mr-2">
              fa-arrow-left
            </v-icon>
            {{ $t("common.back") }}
          </v-btn>

          <v-chip v-if="tag && !showBackButton">
            <span class="primary--text font-weight-bold">
              {{ tag }}
            </span>
          </v-chip>
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-chip v-if="tag && showBackButton" class="mr-3">
            <span class="primary--text font-weight-bold">
              {{ tag }}
            </span>
          </v-chip>
          <v-icon v-if="icon" color="icon-on-color">
            {{ icon }}
          </v-icon>
          <div
            v-if="logo"
            class="logo-circle-image white elevation-12 ml-auto mt-3"
          >
            <img width="80px" height="auto" :src="logo" alt="">
          </div>
        </v-col>
      </v-row>
      <v-row v-if="title || description">
        <v-col cols="12" sm="6">
          <h1 v-if="title" class="text-on-color--text">
            {{ title }}
          </h1>
          <span v-if="description" class="text-on-color--text">{{
            description
          }}</span>
        </v-col>
        <v-col class="text-right" cols="12" sm="6" align-self="center">
          <v-btn
            v-if="button"
            elevation="0"
            small
            color="button-on-color primary--text"
            @click="$emit('click')"
          >
            {{ button }}
            <v-icon small color="primary--text" class="pl-2">
              fa-chevron-circle-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="caption">
        <v-col>
          <v-icon small color="icon-on-color">
            fa-circle-info
          </v-icon>
          <span v-if="caption" class="caption text-on-color--text">
            {{ caption }}
          </span>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<style scoped>
.page-cover {
  min-height: 240px;
}
</style>
