<script>
export default {
  name: "ButtonsThemeSection"
};
</script>

<template>
  <v-container
    id="buttons"
    fluid
  >
    <v-row>
      <v-col>
        <h1>Buttons</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col><h3>Normal</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn>Click Me</v-btn>
      </v-col>
      <v-col>
        <v-btn color="primary">
          Primary
        </v-btn>
      </v-col>
      <v-col>
        <v-btn color="secondary">
          Secondary
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
