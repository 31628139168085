import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  /** ====================================================================================================
     * BURSARY EXPLORER
     ====================================================================================================*/
  {
    path: "/navigators/funding/bursaries",
    component: () =>
      import(
        /* webpackChunkName: "bursaryNavigator" */ "@/modules/FundingNavigator/pages/BursaryNavigatorListingPage/BursaryNavigatorListingPage.vue"
      ),
    name: "bursaryNavigator",
    meta: {
      feature: Feature.BursariesExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "funding", "bursaries"],
      }
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.BursariesExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/funding/bursaries/:name/:id",
    component: () =>
      import(
        /* webpackChunkName: "bursaryNavigator" */ "@/modules/FundingNavigator/pages/BursaryDetailPage.vue"
      ),
    name: "bursaryDetailPage",
    meta: {
      feature: Feature.BursariesExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "funding", "bursaries", "bursary"],
      }
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.BursariesExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },

    props: true,
  },
];
