<script>
export default {
  name: "CTACard",
  props: {
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    time: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    button: {
      type: String,
      default: "Let's Go",
    },
    routeName: {
      type: String,
      required: true,
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Number,
      default: 1
    }
  },
};
</script>

<template>
  <v-card :class="showImage ? `cta-image-card variant-${variant}` : ''" color="primary" max-width="1024px">
    <v-img rounded class="pa-6 white--text">
      <v-row align-content="center">
        <v-col align-self="center">
          <v-chip v-if="tag">
            <span class="primary--text font-weight-bold">
              {{ tag }}
            </span>
          </v-chip>
        </v-col>
        <v-spacer />
        <v-col v-if="icon" class="text-right">
          <v-icon small color="icon-on-color">
            {{ icon }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row v-if="title || subTitle" class="text-center">
        <v-col>
          <h1 v-if="title" class="text-on-color--text">
            {{ title }}
          </h1>
          <span v-if="subTitle" class="text-on-color--text">{{
            subTitle
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="time" class="text-center" dense>
        <v-col>
          <v-icon small color="icon-on-color" class="pr-2">
            fa-clock
          </v-icon>
          <span class="caption text-on-color--text">{{ time }}</span>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <v-btn
            :to="{ name: routeName }"
            elevation="0"
            large
            color="button-on-color primary--text"
          >
            {{ button }}
            <v-icon small color="primary--text" class="pl-2">
              fa-chevron-circle-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<style scoped>

</style>
