<script>
export default {
  name: "ColorsThemeSection",
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Colors</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div style="min-height: 100px" class="primary pa-4">
          Primary
        </div>
      </v-col>
      <v-col>
        <div style="min-height: 100px" class="secondary pa-4">
          Secondary
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div style="min-height: 100px" class="background pa-4">
          Background
        </div>
      </v-col>
      <v-col>
        <div style="min-height: 100px" class="surface pa-4">
          Surface
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div style="min-height: 100px" class="warning pa-4">
          Warning
        </div>
      </v-col>
      <v-col>
        <div style="min-height: 100px" class="info pa-4">
          Info
        </div>
      </v-col>
      <v-col>
        <div style="min-height: 100px" class="error pa-4">
          Error
        </div>
      </v-col>
      <v-col>
        <div style="min-height: 100px" class="success pa-4">
          Success
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <div style="min-height: 100px" class="bg-gradient pa-4">
          Gradient Background
        </div>
      </v-col>
      <v-col cols="6" />
      <v-col cols="6">
        <div style="min-height: 100px" class="bg-gradient-bar pa-4">
          Progress / Measurement Bar Gradient
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
