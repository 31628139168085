import store from "@/store";
import analyticsEvents from "@/library/analyticsEvents.lib";
import router from "@/router/router";

export default [
  /** ====================================================================================================
     * Authentication
     =====================================================================================================*/
  {
    path: "/home",
    name: "home",
    meta: {
      isLoggedOut: true,
      tracking: {
        sections: ["home"],
      },
    },
    component: () => {
      const appConfig = store.getters.getAppConfiguration;

      // TODO: Remove when PluggedIn has its new homepage
      if (appConfig.instanceKey === "pluggedin")
        return import(/* webpackChunkName: "authentication" */ "@/modules/Authentication/pages/PluggedIn/index.vue");

      return import(
        /* webpackChunkName: "authentication" */ "@/modules/Authentication/pages/HomePage.vue"
      );
    },
  },
  {
    path: "/login",
    name: "loginPage",
    meta: {
      isLoggedOut: true,
      tracking: {
        sections: ["login"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Authentication/pages/LoginPage.vue"
      ),
  },
  {
    path: "/auth-callback",
    name: "oauthCallback",
    meta: {
      isLoggedOut: true,
      success: true,
      pageId: "oAuth Callback",

      tracking: {
        sections: ["auth", "oauth callback"],
      },
    },
    beforeEnter: async (to, from, next) => {
      // Handle OAuth Errors
      if (to.query.error) return next(`/home?loginError=${to.query.error}`);

      await store.dispatch("fetchUser");

      // Track return from auth provided
      analyticsEvents.authProviderCallbackSuccessLoaded();

      // Sends the user to the correct place following login
      return router.push({ name: "profileHomePage" });
    },
  },
  {
    path: "/recover",
    name: "recoverPage",
    meta: {
      isLoggedOut: true,
      tracking: {
        sections: ["auth", "password reset"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Authentication/pages/ResetPasswordPage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      const appConfig = store.getters.getAppConfiguration;
      if (!appConfig.authProviders.basic) {
        return next("/");
      }

      next();
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      tracking: {
        sections: ["auth", "logout"],
      },
    },
    beforeEnter: async () => {
      await store.dispatch("logoutUser");
    },
  },
];
