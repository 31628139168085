<template>
  <AuthenticationPageLayout class="pa-0 bg-gradient">
    <v-container style="min-height: calc(100vh - 170px)" fill-height>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          class="white--text caption mt-6"
        >
          <v-icon
            x-large
            color="white"
          >
            fa-circle-exclamation
          </v-icon>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col class="pa-12 pt-6 text-center">
          <h1 class="white--text">
            {{ $t('whoops') }}
          </h1>
          <span class="white--text">
            <a
              href="javascript:history.go(-1)"
              class="yellow--text"
            >
              {{ $t('something_went_wrong_we_cannot_load_page_right_now') }}
              {{ $t('go_back_where_you_came_from') }}
            </a></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            color="yellow"
            to="/"
          >
            {{ $t('back_to_home') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </AuthenticationPageLayout>
</template>

<script>
import AuthenticationPageLayout from "@/components/layout/AuthenticationPageLayout.vue";
import translations from "@/components/locales";
export default {
  name: "ErrorPage",
  i18n: translations("ErrorPage"),
  components: { AuthenticationPageLayout },
  mounted() {
    this.$store.dispatch("fetchUser");
  },
};
</script>

<style scoped>
img {
  max-width: 350px;
}
</style>
