const cvConfig = {
  profile: {
    achievements: { numberRequired: 1 },
    experiences: { numberRequired: 1 },
    jobTasks: { numberRequired: 5 },
    interests: { numberRequired: 1 },
    qualities: { numberRequired: 5 },
    skills: { numberRequired: 5 }
  },
  sections: {
    about: {
      hasFormHelp: true,
      apiMethod: "put",
      apiRoute: "about",
      fields: ["about"],
      progress: {
        type: "existence",
        fields: ["about"]
      }
    },
    workerType: {
      fields: [],
      progress: {
        type: "none"
      }
    },
    personalityGuide: {
      fields: [],
      progress: {
        type: "none"
      }
    },
    names: {
      apiMethod: "put",
      apiRoute: "names",
      fields: ["firstName", "lastName", "nickname"],
      progress: {
        type: "existence",
        fields: ["firstName", "lastName"]
      }
    },
    basicInfo: {
      apiMethod: "patch",
      apiRoute: "profile",
      fields: ["birthDate", "nationality", "languages", "phone", "email", "firstName", "lastName", "nickname", "jobTitle", "drivingLicense", "educationLevel", "gender"],
      progress: {
        type: "existence",
        fields: ["birthDate", "firstName", "lastName"]
      }
    },
    achievements: {
      hasFormHelp: true,
      isSubDocument: true,
      updateApiMethod: "put",
      updateApiRoute: "achievements",
      apiMethod: "post",
      apiRoute: "achievements",
      fields: ["achievements"],
      progress: {
        type: "count",
        fields: ["achievements"],
        amount: 1
      }
    },
    experiences: {
      hasFormHelp: true,
      isSubDocument: true,
      updateApiMethod: "put",
      updateApiRoute: "experiences",
      apiMethod: "post",
      apiRoute: "experiences",
      fields: ["experiences"],
      progress: {
        type: "count",
        fields: ["experiences"],
        amount: 1
      }
    },
    educations: {
      hasFormHelp: false,
      isSubDocument: true,
      updateApiMethod: "put",
      updateApiRoute: "educations",
      apiMethod: "post",
      apiRoute: "educations",
      fields: ["educations"],
      progress: {
        type: "count",
        fields: ["educations"],
        amount: 1
      }
    },
    jobTasks: {
      hasFormHelp: true,
      apiMethod: "post",
      apiRoute: "job-tasks",
      type: "multiselector",
      fields: ["jobTasks"],
      progress: {
        type: "count",
        fields: ["jobTasks"],
        amount: 5
      }
    },
    interests: {
      apiMethod: "post",
      apiRoute: "interests/replace",
      type: "multiselector",
      fields: ["interests"],
      progress: {
        type: "count",
        fields: ["interests"],
        amount: 1
      }
    },
    qualities: {
      hasFormHelp: true,
      apiMethod: "post",
      apiRoute: "qualities",
      type: "multiselector",
      fields: ["qualities"],
      progress: {
        type: "count",
        fields: ["qualities"],
        amount: 5
      }
    },
    skills: {
      hasFormHelp: true,
      apiMethod: "post",
      apiRoute: "skills",
      type: "multiselector",
      fields: ["skills"],
      progress: {
        type: "count",
        fields: ["skills"],
        amount: 1
      }
    },
    references: {
      hasFormHelp: false,
      apiMethod: "post",
      apiRoute: "references",
      type: "multiselector",
      fields: ["references"],
      progress: {
        type: "count",
        fields: ["references"],
        amount: 1
      }
    }
  }
};

module.exports.cvConfig = cvConfig;
