const getDefaultState = () => ({
  timeTotal: 0,
  timeRemaining: 0,
  timeCurrent: 0,
  timerActive: false,
  timerPaused: false,
  timerExpired: false
});

export default {
  state: getDefaultState,
  actions: {
    /**
     * Start the timer with the provided starting time
     * @param commit
     * @returns {Promise<unknown>}
     */
    startTimer({ commit }, payload) {
      return new Promise((resolve) => {
        commit("START_TIMER", payload);
        resolve();
      });
    },
    /**
     * Start the timer with the provided starting time
     * @param commit
     * @returns {Promise<unknown>}
     */
    resetTimer({ commit }) {
      return new Promise((resolve) => {
        commit("RESET_TIMER");
        resolve();
      });
    },
    /**
     * Pause the timer
     * @param commit
     * @returns {Promise<unknown>}
     */
    pauseTimer({ commit }) {
      return new Promise((resolve) => {
        commit("PAUSE_TIMER");
        resolve();
      });
    },
    /**
     * Unpause the timer
     * @param commit
     * @returns {Promise<unknown>}
     */
    unpauseTimer({ commit }) {
      return new Promise((resolve) => {
        commit("UNPAUSE_TIMER");
        resolve();
      });
    },
    /**
     * Stop the timer entirely
     * @param commit
     * @returns {Promise<unknown>}
     */
    stopTimer({ commit }) {
      return new Promise((resolve) => {
        commit("STOP_TIMER");
        resolve();
      });
    },
    reduceTimer({ commit, state }) {
      return new Promise((resolve) => {
        commit("REDUCE_TIMER");

        // if there is only one second left
        if (state.timeRemaining === 0) {
          commit("EXPIRE_TIMER");
        }

        resolve();
      });
    }
  },
  getters: {
    // timer functions
    getCurrentTimeTaken: (state) => state.timeTotal - state.timeRemaining,
    getTimerState: (state) => state,
    getSecondsRemaining: (state) => state.timeRemaining % 60,
    getMinutesRemaining: (state) => Math.floor(state.timeRemaining / 60),
    isTimerActive: (state) => state.timerActive,
    isTimerPaused: (state) => state.timerPaused,
    isTimerExpired: (state) => state.timerExpired
    // Generic Assessment Params
  },
  mutations: {
    START_TIMER(state, payload) {
      state.timerActive = true;
      state.timerPaused = false;
      state.timeTotal = payload;
      state.timerExpired = false;
      state.timeRemaining = payload;
    },
    PAUSE_TIMER(state) {
      state.timerPaused = true;
    },
    UNPAUSE_TIMER(state) {
      state.timerPaused = false;
    },
    STOP_TIMER(state) {
      state.timerActive = false;
    },
    EXPIRE_TIMER(state) {
      state.timerActive = false;
      state.timerExpired = true;
    },
    REDUCE_TIMER(state) {
      --state.timeRemaining;
    },
    RESET_TIMER(state) {
      Object.assign(state, getDefaultState());
    },
  }
};
