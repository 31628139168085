import Vue from "vue";
import App from "./App.vue";
import boot from "./library/boot";
import store from "./store";
import "babel-polyfill";
import i18n from "./i18n";
import router from "./router/router";
import { featureFlagsMixin } from "@/mixins/featureFlagsMixin";
import { fetchConfig, setCachedConfig } from "@/library/appConfiguration";
import { appConfigurationMixin } from "@/mixins/appConfigurationMixin";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import { vuetify as vuetifyPlugin } from "./plugins/vuetify"; // Import the vuetify instance
import "@fortawesome/fontawesome-free/css/all.css";
import VueRouter from "vue-router";

(async function() {
  // App Configuration
  try {
    const config = await fetchConfig();

    setCachedConfig(config);
    await store.dispatch("setAppConfiguration", config);

    // Vue Configuration
    boot.config(Vue);
    // Tools (e.g. Sanitisers)
    boot.tools(Vue);
    // Logging tools (e.g. Sentry)
    boot.logging(Vue, config.integrations, config.instanceKey);
    // Await used to load external scripts (e.g. Adobe Analytics) before app can load
    await boot.analytics(Vue, config.integrations);
    // Whitelabel configuration
    boot.whiteLabel(Vue, config);
    // Plugins
    boot.plugins(Vue);
    // Customer Support Tools (e.g. Crisp Chat)
    boot.customerSupport(Vue, config.integrations);

    const vuetify = vuetifyPlugin(config.themeLight.colors);

    Vue.prototype.$ts = function(content, key) {
      if (!content) return "";

      if (content.translation && content.translation[key])
        return content.translation[key];

      if (content[key]) return content[key];

      return key;
    };

    Vue.mixin(featureFlagsMixin);

    Vue.mixin(appConfigurationMixin);

    Vue.config.ignoredElements = ["lesson-activity"];

    Vue.use(VueTelInputVuetify, {
      vuetify,
    });

    Vue.use(VueRouter);

    new Vue({
      store,
      router,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  } catch (e) {
    document.writeln("Failed to load app configuration. Please try again.");
    // eslint-disable-next-line no-console
    console.error(e);
  }
})();
