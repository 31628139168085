// src/library/mixins/appHeaderMenu.mixin.js

import { mapGetters } from "vuex";

/**
 * Reusable methods for top level navigation
 */
export default {
  computed: {
    ...mapGetters({
      canAccessFeature: "canAccessFeature",
      canSeeFeature: "canSeeFeature"
    })
  },
  methods: {
    /**
     * Checks if a feature can be shown to the user
     * @param feature The feature to be checked
     * @returns {*}
     */
    canShow(feature) {
      return this.canSeeFeature(feature);
    },
    /**
     * Checks if any of the sub-menu items are active using the routers feature meta
     * @param menuItem The top level menu item to be checked
     * @returns {boolean} True if item is active
     */
    isSubItemActive(menuItem) {
      const { feature } = this.$route.meta;

      if (!menuItem.subMenu) {
        return false;
      }

      for (const item of menuItem.subMenu) {
        if (feature === item.feature) {
          return true;
        }
      }

      return false;
    },
    /**
     * Sends the user to the route for a menu item
     * @param menuItem The menu item clicked
     * @returns {*}
     */
    goToRoute(menuItem) {
      // Check if the user is on the current page they have clicked
      // Prevents duplicate route navigation
      if (menuItem.pathName !== this.$route.name) {
        this.$analyticsEvents.clickedLink("navigational", menuItem.feature);

        return this.$router.push({
          name: menuItem.pathName,
          hash: menuItem.hash ? menuItem.hash : ""
        });
      }
    }
  }
};
