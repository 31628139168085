<script>
import translations from "@/components/locales";

export default {
  name: "FatalErrorCard",
  i18n: translations("FatalErrorCard"),
  props: {
    fatalError: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <v-card width="400px" class="ma-auto mt-4" outlined>
    <v-card-title>
      {{ $t("title") }}
    </v-card-title>
    <v-card-text>
      {{ $t("check_connection") }}
      <v-alert type="error" dense class="my-3">
        {{ fatalError }}
      </v-alert>
      {{ $t("issue_persists") }}
    </v-card-text>
  </v-card>
</template>

