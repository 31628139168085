<template>
  <v-card class="pa-2" max-width="100%" min-height="200">
    <div v-if="!loading">
      <v-card-title class="justify-center">
        <v-row class="text-right">
          <v-col class="text-center">
            {{ header }}
          </v-col>
          <v-col cols="1" class="pa-0">
            <v-icon class="ml-auto pa-2" @click="$emit('close')">
              fa-xmark
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <template v-for="(item, n) in helpContent">
          <v-expansion-panels
            :key="`${n}_help-content`"
            class="border-accordion"
            multiple
          >
            <v-expansion-panel data-cy="helpContentComponentExpansionPanel">
              <v-expansion-panel-header>
                <h3>
                  {{ item.question }}
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <block-content :blocks="item.answer" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>
    </div>

    <div v-if="loading">
      <v-skeleton-loader type="article" />
    </div>
  </v-card>
</template>

<script>
import api from "@/library/api.lib";

export default {
  name: "HelpDialog",
  props: {
    slug: {
      type: String,
      default: "",
      required: true,
    },
    header: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      helpContent: null,
    };
  },
  watch: {
    async "$i18n.locale"() {
      this.getHelpContent();
    },
  },
  mounted() {
    this.getHelpContent();
  },
  methods: {
    /**
     * Uses the slug prop to get the content from Sanity needed to populate the help form.
     */
    async getHelpContent() {
      this.loading = true;

      try {
        const response = await api().get(
          `help/${this.slug}?lang=${this.$i18n.locale}`
        );
        this.helpContent = response.data.translation
          ? response.data.translation.helpContent
          : response.data.helpContent;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
