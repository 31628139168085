<script>
export default {
  name: "ExpansionPanelsThemeSection"
};
</script>

<template>
  <v-container
    id="accordions"
  >
    <v-row>
      <v-col>
        <h1 class="pb-3">
          Expansion panels
        </h1>

        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header> Item</v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="pb-3">
          Expansion With Location
        </h1>

        <v-expansion-panels class="dashed-expansion-panel" multiple flat>
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header
              class="dashed-expansion-panel-header"
            >
              <span><v-icon class="primary--text"> fa-location-dot </v-icon>
                Item</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="dashed-expansion-panel-content"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
