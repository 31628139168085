export default [
  /** ====================================================================================================
   * SETTINGS
   =====================================================================================================*/
  {
    path: "/settings",
    component: () => import(/* webpackChunkName: "settings" */ "@/modules/Settings/pages/SettingsPage.vue"),
    meta: {
      feature: "Settings",
      tracking: {
        sections: ["settings"]
      },
    },
    name: "settings",
    props: true,
    children: [
      {
        path: "account",
        component: () => import(/* webpackChunkName: "account-settings" */ "@/modules/Settings/pages/AccountSettingsPage/AccountSettingsPage.vue"),
        name: "accountSettingsPage",
      },
      {
        path: "security",
        component: () => import(/* webpackChunkName: "security-settings" */ "@/modules/Settings/pages/SecuritySettingsPage/SecuritySettingsPage.vue"),
        name: "securitySettingsPage",
      }
    ]
  }
];
