<template>
  <v-app-bar class="header-toolbar mb-0" flat>
    <AppLogo
      class="mt-2 mt-sm-0 mr-2 mr-sm-0"
      height="20px"
      router-link-name="home"
    />

    <v-spacer />
    <LanguageSwitcher class="mr-2" />
    <v-btn
      v-if="showHelpButton"
      ref="onboardingHelpButton"
      outlined
      data-cy="onboardingHelpButton"
      color="primary"
      class="mr-2 light-outlined-button"
      @click="showHelp = !showHelp"
    >
      <v-icon small class="mr-1">
        fa-question-circle
      </v-icon>
      {{ $t("help") }}
    </v-btn>
    <v-btn
      v-if="showSignup"
      color="primary"
      elevation="0"
      :to="{ name: 'home' }"
    >
      Sign up
    </v-btn>
    <v-btn v-else outlined color="primary" :to="{ name: 'loginPage' }">
      Login
    </v-btn>
    <v-btn
      v-if="isStateUserValid"
      :to="{ name: 'profileHomePage' }"
      color="yellow"
      class="mr-6"
      data-cy="unauthenticated-header-dashboard-button"
    >
      Home
    </v-btn>
    <AppHeaderUserDropdownMenu v-if="isStateUserValid" />

    <v-dialog v-model="showHelp" max-width="550" class="justify-center">
      <HelpDialog
        class="justify-center"
        slug="account-help"
        :header="$t('help_header')"
        @close="showHelp = !showHelp"
      />
    </v-dialog>
  </v-app-bar>
</template>

<script>
import AppLogo from "@/components/common/AppLogo.vue";
import { mapGetters } from "vuex";
import AppHeaderUserDropdownMenu from "@/components/navigation/AppHeaderUserDropdownMenu.vue";
import HelpDialog from "@/components/common/HelpDialog.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";

export default {
  name: "UnauthenticatedAppHeader",
  components: {
    LanguageSwitcher,
    HelpDialog,
    AppHeaderUserDropdownMenu,
    AppLogo,
  },
  props: {
    showSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      voucherCode: "",
      showHelp: false,
    };
  },
  computed: {
    ...mapGetters(["isStateUserValid"]),
    showHelpButton() {
      return !!this.appConfiguration.integrations.crispChatID;
    },
  },
};
</script>
