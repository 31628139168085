import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  /** ====================================================================================================
     * OCCUPATIONS NAVIGATOR
     ====================================================================================================*/
  {
    path: "/navigators/careers/all",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/OccupationsNavigator/pages/OccupationsNavigatorCareersListingPage/OccupationsNavigatorCareersListingPage.vue"
      ),
    name: "occupationsNavigatorCareers",
    meta: {
      feature: Feature.CareerExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "occupations"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.CareerExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/careers/matches",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/OccupationsNavigator/pages/OccupationsNavigatorMatchesListingPage/OccupationsNavigatorMatchesListingPage.vue"
      ),
    name: "occupationsNavigatorMatches",
    meta: {
      feature: Feature.CareerExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "occupations"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.CareerMatches)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/careers/favourites",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/OccupationsNavigator/pages/OccupationsNavigatorFavouritesListingPage/OccupationsNavigatorFavouritesListingPage.vue"
      ),
    name: "occupationsNavigatorFavourites",
    meta: {
      feature: Feature.CareerExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "occupations"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.CareerExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    name: "careerDetailPage",
    path: "/navigators/careers/careers/:slug/:id",
    component: () =>
      import(
        /* webpackChunkName: "careerExplorer" */ "@/modules/OccupationsNavigator/pages/CareerDetailPage"
      ),
    meta: {
      feature: Feature.CareerExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "careers", "career detail"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.CareerExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
];
