export default {
  common: {
    "add": "Add",
    "asterisk_indicates_required": "* Indicates required",
    "click_to_drag": "Click to Drag",
    "delete": "Delete",
    "edit": "Edit",
    "end_date_after_start_date": "End Date must be after Start Date",
    "end_date": "End Date",
    "first_name_required": "First Name is required",
    "start_date": "Start Date",
    "end_date_required": "End Date is required",
  }
};
