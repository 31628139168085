<template>
  <div>
    <!-- Top Level navigation items -->
    <template v-for="menuItem in navigationMenuItems">
      <v-menu
        v-if="
          menuItem.hideForAccounts &&
            !menuItem.hideForAccounts.includes(accountType)
        "
        :key="menuItem.feature"
        bottom
        offset-y
        class="top-menu-sub-menu app-header-bar-text--text"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            class="hidden-xs-only app-header-bar-text--text"
            :disabled="!isAssessmentComplete(AssessmentType.HollandCode)"
            :input-value="isSubItemActive(menuItem)"
            :to="!menuItem.subMenu ? { name: menuItem.pathName } : null"
            v-on="on"
          >
            {{ $t(`${menuItem.slug}`) }}
          </v-btn>
        </template>

        <!-- Sub Menu Navigation Items -->
        <v-list v-if="menuItem.subMenu">
          <SubMenuItem
            v-for="subMenuItem in menuItem.subMenu"
            v-show="canShow(subMenuItem.feature)"
            :key="subMenuItem.feature"
            :slug="subMenuItem.slug"
            :feature="subMenuItem.feature"
            :available="canAccessFeature(subMenuItem.feature)"
            :icon="subMenuItem.icon"
            @click="goToRoute(subMenuItem)"
          />
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script>
import { navigationMenuItems } from "@/config/navigation.config";
import appHeaderMixin from "@/library/mixins/appHeaderMenu.mixin";
import { mapGetters } from "vuex";
import SubMenuItem from "@/components/navigation/SubMenuItem.vue";
import { AssessmentType } from "@YenzaCT/sdk";
import translations from "@/components/locales";

/**
 * Top level navigation for desktop
 */
export default {
  name: "TopNavigationBar",
  i18n: translations("TopNavigationBar"),
  components: {
    SubMenuItem,
  },
  mixins: [appHeaderMixin],
  data: () => ({
    navigationMenuItems,
  }),
  computed: {
    AssessmentType() {
      return AssessmentType;
    },
    ...mapGetters({
      accountType: "getAccountType",
      isAssessmentComplete: "isAssessmentComplete",
    }),
  },
};
</script>
