import { Feature } from "@YenzaCT/sdk";

export default [

  {
    path: "/profile/home",
    name: "profileHomePage",
    meta: {
      feature: Feature.Profile,
      tracking: {
        sections: ["profile", "home"]
      }
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/modules/Profile/pages/ProfilePage/ProfilePage.vue"),
  }
];
