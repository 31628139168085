import { cvConfig } from "../config/config";

const availableProfileSections = cvConfig.sections;

const getDefaultState = () => ({
  profileFormOpen: false,
  formValid: false,
  formErrorStatus: false,
  formChanged: false,
  formHelp: false,
  section: "",
  apiMethod: "",
  apiRoute: "",
  updateApiMethod: "",
  updateApiRoute: "",
  isSubDocument: false,
  formData: {},

  // Multi-selector Forms
  multiSelectorForm: false,
  recordAmountRequired: 0,
  selectedRecords: []
});

export default {
  state: getDefaultState,
  actions: {
    resetCvState({ commit }) {
      commit("RESET_CV_STATE");
    },
    /**
     * Triggered when the user opens a form in the cv
     *
     * @param commit
     * @constructor
     */
    openProfileForm({ commit }, { section, formData }) {
      commit("OPEN_PROFILE_FORM", { section, formData });

      // If this form requires multiple records to be selected (skills, interests, etc)
      if (availableProfileSections[section].type === "multiselector")
        commit("SET_FORM_RECORD_COUNTER", availableProfileSections[section].progress.amount);
    },
    addMultiSelectorRecord({ state, commit }, record) {
      commit("ADD_MULTI_SELECTOR_RECORD", record);

      if (state.selectedRecords.length === state.recordAmountRequired)
        commit("SET_FORM_VALIDATION", true);
    },
    resetMultiSelector({ commit }) {
      commit("RESET_MULTI_SELECTOR");
    },
    removeMultiSelectorRecord({ commit, state }, recordIndex) {
      if (state.selectedRecords[recordIndex]) {
        commit("REMOVE_MULTI_SELECTOR_RECORD", recordIndex);
      }
    },
    /**
     * Triggered when the user opens a form in the cv
     *
     * @param commit
     * @constructor
     */
    closeProfileForm({ commit }) {
      commit("RESET_MULTI_SELECTOR");
      commit("CLOSE_PROFILE_FORM");
    },
    setFormValidation({ commit }, status) {
      commit("SET_FORM_VALIDATION", status);
    },
  },
  getters: {
    isProfileFormOpen: (state) => state.profileFormOpen,
    hasHelp: (state) => state.formHelp,
    isFormValid: (state) => state.formValid,
    isFormMultiSelector: (state) => state.multiSelectorForm,
    hasFormChanged: (state) => state.formChanged,
    getFormSection: (state) => state.section,
    getFormData: (state) => state.formData,
    getApiSettings: (state) => ({
      method: state.apiMethod,
      route: state.apiRoute,
      updateMethod: state.updateApiMethod,
      isSubDocument: state.isSubDocument,
      updateRoute: state.updateApiRoute
    }),
    getProfileSectionData: (state, getters, rootState) => (section) => {
      const sectionData = {};
      const { fields } = availableProfileSections[section];

      for (const value of fields) {
        sectionData[value] = rootState.user.profile[value];
      }

      return sectionData;
    },
    getFormErrorStatus: (state) => state.formErrorStatus,

    // Multi-selector
    getAmountOfRecordsRequired: (state) => state.recordAmountRequired,
    getAmountOfRecordsStillRequired: (state) =>
      state.recordAmountRequired - state.selectedRecords.length,
    getCurrentRecordCount: (state) => state.selectedRecords.length,
    getSelectedRecords: (state) => state.selectedRecords
  },
  mutations: {
    RESET_CV_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_FORM_VALIDATION(state, status) {
      state.formValid = status;
    },
    SET_FORM_RECORD_COUNTER(state, numberOfRecords) {
      state.multiSelectorForm = true;
      state.recordAmountRequired = numberOfRecords;
    },
    ADD_MULTI_SELECTOR_RECORD(state, record) {
      state.selectedRecords.push(record);
    },
    REMOVE_MULTI_SELECTOR_RECORD(state, recordIndex) {
      state.selectedRecords.splice(recordIndex, 1);
    },
    RESET_MULTI_SELECTOR(state) {
      state.selectedRecords = [];
      state.formValid = false;
    },
    OPEN_PROFILE_FORM(state, { section, formData }) {
      state.profileFormOpen = true;
      state.section = section;
      state.formName = section;
      state.apiMethod = availableProfileSections[section].apiMethod;
      state.apiRoute = availableProfileSections[section].apiRoute;
      state.updateApiMethod = availableProfileSections[section].updateApiMethod;
      state.updateApiRoute = availableProfileSections[section].updateApiRoute;
      state.isSubDocument = availableProfileSections[section].isSubDocument;
      state.formHelp = availableProfileSections[section].hasFormHelp;
      state.formData = formData;
    },
    CLOSE_PROFILE_FORM(state) {
      state.profileFormOpen = false;
      state.formName = null;
    }
  }
};
