/**
 * AXIOS Api for the YAPI
 * Makes use of CSRF protection using Double Submit Cookies
 * See:
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie
 */

import axios from "axios";
import store from "@/store";
import environmentConfig from "@/config/environment.config";

/**
 * Generate a random token to be used for CSRF
 * @returns {string}
 */
function generateToken() {
  const intArray = window.crypto.getRandomValues(new Uint32Array(16));
  return Array.from(intArray).map((int) => int.toString(16)).join("");
}

const csrfToken = generateToken();

export default function(authFailureRedirect = true) {
  // Set the CSRF Token in the header
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
    "Expires": "0",
    "X-CSRF": csrfToken
  };

  // Set the CSRF Token as a cookie
  const date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `_csrf=${csrfToken}; secure=true; SameSite=Lax; ${expires}; domain=${environmentConfig.cookieDomain}; path=/`;

  // Create the client, including credentials
  const client = axios.create({
    baseURL: environmentConfig.serverUrl,
    headers: headers,
    withCredentials: true,
  });

  // When a request begins, set the loading to true to indicate a page is loading
  client.interceptors.request.use((config) => {
    // Don't load
    if (config.url !== "auth/ping")
      store.dispatch("setLoading", true);
    return config;
  }, (error) => {
    store.dispatch("setLoading", false);
    return Promise.reject(error);
  });

  // Response interceptor to detect if a user is logged out
  client.interceptors.response.use(
    (config) => {
      // Passes cookies with request
      config.withCredentials = true;

      // Sets the state to no longer loading
      store.dispatch("setLoading", false);

      return config;
    },
    (error) => {
      // Sets the state to no longer loading
      store.dispatch("setLoading", false);

      // If they are logged out, end their session
      // Don't process this in certain situations, eg when the user is logging into the login form
      if (
        error.response &&
        error.response.status === 401 &&
        authFailureRedirect
      ) {
        store.dispatch("logoutUser");
      }

      return Promise.reject(error);
    },
  );

  return client;
}
