<template>
  <v-container class="mt-3">
    <div class="w3-black " />
    <div class="w3-grey bg-gradient-bar" :style="{ width: progress + '%' }" />
  </v-container>
</template>

<script>

export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.w3-black {
  background-color: #E1E1E1 !important;
  height:5px;
  width:100%;

}
.w3-grey {
  background-color: #000 !important;
  z-index: 1;
  border-radius: 25px;
  height:24px;
  margin-top: -15px;
}

</style>
