export default [
  {
    name: "privacyPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["privacy"],
      },
    },
    path: "/privacy",
    component: () =>
      import(
        /* webpackChunkName: "policyPages" */ "@/modules/Policies/pages/PrivacyPage/index.vue"
      ),
  },
  {
    name: "termsPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["terms"],
      },
    },
    path: "/terms",
    component: () =>
      import(
        /* webpackChunkName: "policyPages" */ "@/modules/Policies/pages/TermsAndConditionsPage/index.vue"
      ),
  },
];
