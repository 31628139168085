import api from "@/library/api.lib";

export async function loadAppConfiguration() {
  const cachedConfig = getCachedConfig();

  if (cachedConfig) {
    return cachedConfig;
  } else {
    return await fetchConfig();
  }
}

export async function fetchConfig() {
  const res = await api().get("app-configuration");
  const config = res.data;
  const theme_light = config.themeLight;
  const theme_dark = config.themeDark;

  return {
    ...config,
    theme_light,
    theme_dark,
  };
}

function getCachedConfig() {
  const json = localStorage.getItem("app_config");
  if (json) {
    return JSON.parse(json);
  }

  return null;
}

export function setCachedConfig(config) {
  const json = JSON.stringify(config);
  localStorage.setItem("app_config", json);
}
