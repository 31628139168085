function getCookie(name) {
  return (
    (name = (document.cookie + ";").match(new RegExp(name + "=.*;"))) &&
    name[0].split(/=|;/)[1]
  );
}

function unsetCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function setCookie(name, value, minutes) {
  let expires = "";
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function setSignupCookies() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const selectedPlanParam = urlParams.get("selectedPlan");
  const countryParam = urlParams.get("country");
  const couponCodeParam = urlParams.get("coupon");
  const voucherCodeParam = urlParams.get("code");

  if (countryParam) {
    setCookie("plan-country", countryParam, 240);
  }
  if (selectedPlanParam) {
    setCookie("selected-plan", selectedPlanParam, 240);
  }
  if (selectedPlanParam) {
    setCookie("package", selectedPlanParam, 240);
  }
  if (voucherCodeParam) {
    setCookie("voucher-code", voucherCodeParam, 240);
  }
  if (couponCodeParam) {
    setCookie("coupon-code", couponCodeParam, 240);
  }
}

function unsetSignupCookies() {
  unsetCookie("selected-plan");
  unsetCookie("plan-country");
  unsetCookie("package");
  unsetCookie("voucher-code");
  unsetCookie("coupon-code");
  unsetCookie("skip-verification");
}

export {
  setSignupCookies,
  getCookie,
  setCookie,
  unsetCookie,
  unsetSignupCookies,
};
