<template>
  <v-list-item
    :class="isItemActive(feature) ? 'grey lighten-2 font-weight-bold' : ''"
    :disabled="disableSubMenuItem || !available" link dense
    @click="$emit('click')"
  >
    <v-list-item-icon>
      <v-icon v-if="!available">
        fa-lock
      </v-icon>
      <v-icon v-if="available">
        {{ icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title :class="isItemActive(feature) ? 'font-weight-bold' : ''" class="body-2">
      {{ $t(`${slug}`) }}
      <span
        v-if="!available"
        class="green--text font-italic"
      >
        <br>
        {{ $t('upgrade_to_access') }}
      </span>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { Feature } from "@YenzaCT/sdk";
import translations from "@/components/locales";

/**
 * Reusable menu item for Top and Side Navigation
 */
export default {
  name: "SubMenuItem",
  i18n: translations("TopNavigationBar"),
  props: {
    /**
     * The title of the section
     */
    slug: {
      type: String,
      default: "",
      required: true,
    },
    /**
     * Indicates if this users package can access this section
     */
    available: {
      type: Boolean,
      default: false,
      required: true,
    },
    /**
     * The feature this menu item links to
     */
    feature: {
      type: String,
      required: true,
    },
    /**
     * Menu Item icon
     */
    icon: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isAssessmentComplete: "isAssessmentComplete"
    }),
    /**
     * Disables all menu items except Worker Type if Holland code is not complete
     * @param menuItem
     * @returns {boolean}
     */
    disableSubMenuItem() {
      if (this.feature !== Feature.WorkerTypeReport && !this.isAssessmentComplete("holland_code"))
        return true;
      return false;
    }
  },
  methods: {
    /**
     * Uses the sub menu to check if there is currently an active item.
     * @param subMenu
     * @returns {boolean}
     */
    isItemActive(menuItem) {
      const { feature } = this.$route.meta;

      if (feature)
        return feature === menuItem;

      return false;
    },
  }
};
</script>
