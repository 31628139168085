<template>
  <v-container class="authentication-page-wrapper" fluid>
    <slot name="background" />

    <v-row justify="center" class="authentication-page-header px-6">
      <v-col cols="3" md="4">
        <AppLogo router-link-name="home" data-cy="headerLogo" />
      </v-col>

      <v-spacer />

      <v-col cols="8" md="6" class="text-right" align-self="center">
        <div v-if="!isStateUserValid">
          <span v-if="showSignUpButton" class="caption pr-2 hidden-xs-only">
            {{ $t("common.dont_have_an_account") }}
          </span>
          <v-btn
            v-if="showSignUpButton"
            :to="{ name: 'freeSignup' }"
            color="primary white--text"
            class="rounded ma-auto"
            data-cy="headerSignUpButton"
          >
            {{ $t("common.sign_up") }}
          </v-btn>
          <v-btn
            v-if="$route.name !== 'home'"
            :to="{ name: 'home' }"
            color="primary white--text"
            class="rounded ma-auto ml-2"
            data-cy="headerLoginButton"
            text
          >
            {{ $t("common.log_in") }}
          </v-btn>
        </div>

        <v-btn
          v-if="showLoginButton && isStateUserValid"
          :to="{ name: 'logout' }"
          small
          outlined
          color="white"
          class="rounded"
        >
          {{ $t("back_to_appname", { appName: appConfiguration.appName }) }}
        </v-btn>

        <v-btn
          v-if="isStateUserValid"
          :to="{ name: 'logout' }"
          color="primary white--text"
          class="rounded ma-auto"
        >
          {{ $t("common.logout") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-layout column justify-space-between class="welcome-content">
      <div class="text-center">
        <div :class="$vuetify.breakpoint.xsOnly ? '' : 'pt-12'">
          <slot />
        </div>
      </div>

      <div>
        <v-row class="mt-2 px-2 pb-2">
          <v-col cols="12" class="caption">
            © {{ /\d{4}/.exec(Date())[0] }} {{ appConfiguration.appName }} - v{{
              appVersion
            }}
            -
            <router-link
              v-if="showTerms"
              class="black--text"
              :to="{ name: 'termsPage' }"
            >
              {{ $t("common.terms_of_use") }}
            </router-link>
            <span v-if="showPrivacy && showTerms">
              {{ $t("common.and") }}
            </span>
            <router-link
              v-if="showPrivacy"
              class="black--text"
              :to="{ name: 'privacyPage' }"
            >
              {{ $t("common.privacy_agreement") }}
            </router-link>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import AppLogo from "@/components/common/AppLogo.vue";
import { mapGetters } from "vuex";
import translations from "@/components/locales";

const { version } = require("../../../package.json");

export default {
  name: "AuthenticationPageLayout",
  i18n: translations("AuthenticationPageLayout"),
  components: {
    AppLogo,
  },
  props: {
    showLoginButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLogin: false,
      appVersion: version,
    };
  },
  computed: {
    ...mapGetters(["isStateUserValid"]),
    showPrivacy() {
      return this.appConfiguration.policies.privacy;
    },
    showTerms() {
      return this.appConfiguration.policies.termsAndConditions;
    },
    showSignUpButton() {
      return !!this.appConfiguration.authProviders.basic;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-content {
  min-height: calc(100vh - 70px);
}

.authentication-page-wrapper {
  min-height: 100vh;
  background: repeating-linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.1) 1px,
      rgba(0, 0, 0, 0.1) 2px
    ),
    linear-gradient(
      164deg,
      var(--v-gradient-start-base),
      var(--v-gradient-end-base)
    );
}

.authentication-page-header {
  position: sticky;
  top: 0;
  z-index: 10000;
  background: #fff;
}
</style>
