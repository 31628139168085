import { Feature } from "@YenzaCT/sdk";

export default [
  /** ====================================================================================================
   * CV
   =====================================================================================================*/
  {
    path: "/cv",
    name: "cv",
    meta: {
      section: "cv",
      feature: Feature.Cv,
      pageId: "CV",
      tracking: {
        sections: ["my profile and cv"]
      },
      breadcrumbs: [
        { title: "Actions" },
        { title: "CV", routeName: "cv" }
      ]
    },
    component: () => import(/* webpackChunkName: "cv" */ "@/modules/CV/pages/CVPage")
  }
];
