<template>
  <div v-if="breadcrumbItems" class=" caption pl-2" fluid>
    <template v-for="(crumb, index) in breadcrumbItems">
      <span
        :key="index"
        :class="index === 0 ? 'app-header-bar-text--text font-weight-bold' : ''"
      >
        <router-link v-if="crumb.routeName" :to="{ name: crumb.routeName }" class="breadcrumbs-bar-link app-header-bar-link--text">
          {{ crumb.title }}
        </router-link>
        <router-link v-if="crumb.path" :to="crumb.path" class="breadcrumbs-bar-link app-header-bar-link--text">
          {{ crumb.title }}
        </router-link>
        <span v-show="!crumb.routeName && !crumb.path" :class="index === 0 ? 'app-header-bar-link--text font-weight-bold' : 'app-header-bar-text--text'">
          {{ crumb.title }}
        </span>
      </span>

      <span
        v-if="index === 0 && breadcrumbItems.length > 1"
        :key="`${index}_sep_first`"
        class="font-weight-bold px-1 app-header-bar-text--text"
      >
        •
      </span>

      <span
        v-else-if="index < breadcrumbItems.length - 1"
        :key="`${index}_sep`"
        class="font-weight-bold app-header-bar-text--text px-1"
      >
        /
      </span>
    </template>
  </div>
</template>

<script>
/**
 * Breadcrumb toolbar for navigation
 */
export default {
  name: "BreadcrumbBar",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    breadcrumbItems() {
      if (this.breadcrumbs && this.breadcrumbs.length > 0)
        return this.breadcrumbs;

      return this.$route.meta.breadcrumbs;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>
