<script>
export default {
  name: "ChipsThemeSection"
};
</script>

<template>
  <v-container
    id="chips"
    fluid
  >
    <v-row>
      <v-col>
        <h1>Chips</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col><h3>Colored</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-chip class="ma-2">
            Default
          </v-chip>
          <v-chip class="ma-2" color="primary">
            Primary
          </v-chip>
          <v-chip class="ma-2" color="secondary">
            Secondary
          </v-chip>
          <v-chip class="ma-2" color="red" text-color="white">
            Red Chip
          </v-chip>
          <v-chip class="ma-2" color="green" text-color="white">
            Green Chip
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
