import analytics from "@/library/analytics.lib";
import { ASSESSMENT_LABELS, EXPLORER_LABELS } from "@/library/constants";
import { cloneDeep } from "lodash";

/**
 * Tracks when the user finishes an assessment
 * @param assessmentId The ID of the assessment
 */
function finishedAssessment(assessmentId) {
  analytics.sendEvent(
    "Assessments: Finished",
    {
      assessment: ASSESSMENT_LABELS[assessmentId]
    },
    "assessments",
    "finish_assessment"
  );
}

function clickedLink(intent, name, scope = "cta") {
  analytics.sendEvent(
    "Clicked Link",
    {
      intent,
      name,
      scope
    },
    "links",
    "clicked_link",
    "linkClick"
  );
}

function formStart(name) {
  analytics.sendEvent(
    "Form Started",
    {
      name
    },
    "forms",
    "form_started",
    "formStart"
  );
}

function formComplete(name) {
  analytics.sendEvent(
    "Form Completed",
    {
      name
    },
    "forms",
    "form_completed",
    "formComplete"
  );
}

/**
 * Records a page view
 * @param route The route object for the page to track
 * @param subSections The subsections to add to the tracking
 */
function pageView(route, subSections) {
  let sections = route.meta.tracking.sections ? cloneDeep(route.meta.tracking.sections) : [];
  const routeName = route.name;
  const params = route.params;
  const externalPage = route.meta.isLoggedOut ? route.meta.isLoggedOut : null;

  // Add the final pageName to the sections list (if it exists)
  if (subSections) sections = sections.concat(subSections);

  // Join the sections together with : for the full page name
  const pageName = sections.join(":");

  // Convert the sections into their subsections
  const pageSubSections = {
    pageSubSection1: "",
    pageSubSection2: "",
    pageSubSection3: "",
    pageSubSection4: "",
  };
  let pageSections = "";

  for (let i = 0; i < sections.length; i++) {
    if (i < sections.length && i > 0) pageSections += ":";

    pageSections += sections[i];

    pageSubSections[`pageSubSection${i + 1}`] = pageSections;
  }

  analytics.sendEvent(
    "Opened Page",
    {
      pageName,
      routeName,
      params,
      externalPage,
      pageSubSections
    },
    "pages",
    "open_page",
    "pageView"
  );

  // Starts a time event that tracks time between "Opened Page" and "Exited Page" events
  analytics.timeEvent("Exited Page");
}

function openedPage(pageName, routeName, params, externalPage = false) {
  analytics.sendEvent(
    "Opened Page",
    {
      pageName,
      routeName,
      params,
      externalPage
    },
    "pages",
    "open_page",
    "pageView"
  );
  analytics.timeEvent("Exited Page");
}

function exitedPage(pageName, externalPage = false) {
  analytics.sendEvent(
    "Exited Page",
    {
      pageName,
      externalPage
    },
    "pages",
    "exit_page"
  );
}

/**
 * Tracks when a user opens the app
 * @param pageName The name of the page the user visited
 */
function openedApp() {
  analytics.sendEvent("Opened App", {}, "app", "open_app", "openApp");
}

/**
 * Tracks when a user attempts to check an access codes validity
 * @param accessCode The access code the user is checking
 * @param wasCodeFound Does the code exist
 * @param isCodeValid Is the code valid
 * @param isCodeUsed Is the code already in use
 */
function checkAccessCodeValidity(accessCode, wasCodeFound, isCodeValid, isCodeUsed) {
  analytics.sendEvent(
    "Access Code: Check Validity",
    {
      accessCode,
      wasCodeFound: wasCodeFound ? "Not Found" : "Found",
      isCodeValid: isCodeValid ? "Valid" : "Invalid",
      isCodeUsed: isCodeUsed ? "In Use" : "Not Used"
    },
    "onboarding",
    "check_access_code"
  );
}

function redeemAccessCode(accessCode) {
  analytics.sendEvent(
    "Access Code: Redeemed",
    {
      accessCode
    },
    "onboarding",
    "redeem_access_code"
  );
}

/**
 * Tracks when the user has created a new account
 * @param signupMethod
 * @param accountType
 * @param coupon
 */
function createdAccount(signupMethod, accountType, coupon) {
  analytics.sendEvent(
    "Onboarding: Account Created",
    {
      signupMethod,
      accountType,
      coupon
    },
    "onboarding",
    "create_account"
  );
}

/**
 * Tracks when a user logs into the app
 * @param authenticationMethod The method they used to authenticate, eg email, snappilify
 */
function authenticatedUser(authenticationMethod) {
  analytics.sendEvent("Authenticate", { authenticationMethod }, "authentication", "login");
}

function openedAuthProvider(provider) {
  analytics.sendEvent(
    "oAuth: Opened",
    {
      provider
    },
    "consent",
    "oauth_opened"
  );
}
function authProviderCallbackSuccessLoaded() {
  analytics.sendEvent("oAuth: Success", {}, "consent", "oauth_success", "oauthSuccess");
}

/** **************************************************************************
 * EXPLORERS
 ****************************************************************************/

function addedCareerToFavourites(career) {
  analytics.sendEvent(
    "Careers: Added Career Favourites",
    {
      career
    },
    "careers",
    "add_career_to_favourites"
  );
}

/**
 * Tracks when the user opens (or returns to) an explorer
 * @param explorerId
 */

function visitedExplorerExternalWebsite(explorerId, content, provider, contentItem, link) {
  analytics.sendEvent(
    "Explorers: Open External Link",
    {
      explorer: EXPLORER_LABELS[explorerId],
      content,
      provider,
      contentItem,
      link
    },
    "explorers",
    "open_external_link"
  );
}

function openedSkillExplorerProviderBenefit(title, provider) {
  analytics.sendEvent(
    "Explorers: Opened Skill Provider Benefit",
    {
      title,
      provider
    },
    "explorers",
    "open_skill_provider_benefit"
  );
}

/** **************************************************************************
 * CV
 ****************************************************************************/

function openedCvEditSection(section) {
  analytics.sendEvent(
    "CV: Opened Section",
    {
      section
    },
    "cv",
    "open_section"
  );
}

function updatedCv(section, completedSections, incompleteSections, percentageComplete) {
  analytics.sendEvent(
    "CV: Updated Section",
    {
      section,
      completedSections,
      incompleteSections,
      percentageComplete
    },
    "cv",
    "update_section"
  );
}

function openedCvSectionHelpDialog(section) {
  analytics.sendEvent(
    "CV: Opened Help",
    {
      section
    },
    "cv",
    "open_help"
  );
}

function addedCustomCvRecord(section, recordValue) {
  analytics.sendEvent(
    "CV: Added Custom Record",
    {
      section,
      recordValue
    },
    "cv",
    "add_custom_record"
  );
}

function submittedConsent() {
  analytics.sendEvent("Consent: Submitted", {}, "consent", "consent_submitted");
}

function openedConsent() {
  analytics.sendEvent("Consent: Opened", {}, "consent", "consent_opened");
}

export default {
  clickedLink,
  formStart,
  formComplete,
  finishedAssessment,
  pageView,
  exitedPage,
  openedApp,
  checkAccessCodeValidity,
  redeemAccessCode,
  createdAccount,
  authenticatedUser,
  visitedExplorerExternalWebsite,
  addedCareerToFavourites,
  openedSkillExplorerProviderBenefit,
  openedCvEditSection,
  updatedCv,
  openedCvSectionHelpDialog,
  addedCustomCvRecord,
  openedAuthProvider,
  authProviderCallbackSuccessLoaded,
  submittedConsent,
  openedConsent,
};
