import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader

const vuetifyConfiguration = (lightTheme) => ({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3730A3",
        secondary: "#ffa700",

        "icon-on-color": "#FFFFFF",
        "text-on-color": "#FFFFFF",
        "button-on-color": "#FFFFFF",

        "app-header-bar": "#FFFFFF",
        "app-header-bar-text": "#000000",

        "gradient-start": "#201460",
        "gradient-end": "#4f3cb6",
        "bar-gradient-start": "#201460",
        "bar-gradient-end": "#4f3cb6",

        // Worker Types
        investigative: "#3B82F6",
        conventional: "#EF4444",
        realistic: "#EA580C",
        enterprising: "#8B5CF6",
        social: "#EC4899",
        artistic: "#16A34A",

        data_01_primary: "#7C3AED",
        data_02_primary: "#2563EB",
        data_03_primary: "#059669",
        data_04_primary: "#65A30D",
        data_05_primary: "#F97316",

        rating_1: "#ff3100",
        rating_2: "#ff7900",
        rating_3: "#ffa700",
        rating_4: "#36c600",
        rating_5: "#00ad65",
        // Add the rest of your theme configuration here
        ...lightTheme,
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});

Vue.use(Vuetify);

export const vuetify = (config) => new Vuetify(vuetifyConfiguration(config));
