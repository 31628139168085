import profile from "@/modules/CV/store/progress.module";

export default {
  actions: {
    /**
     * Takes in a user object and updates their system wide progress based on their data
     * @param dispatch
     * @param user
     */
    async setUserProgress({ dispatch }, { user, module = "all"}) {
      // Set career progress
      if (module === "all" || module === "profile")
        await dispatch("setOverallProfileProgress", user.profile);
    },
  },
  modules: {
    profile
  }
};
