export function loadLocaleMessages(context) {
  const messages = {};

  context.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = context(key);
    }
  });

  return messages;
}

export function getLocalesForComponent(locales, component) {
  const allMessages = locales;
  const componentMessages = {};

  Object.keys(allMessages).forEach((lang) => {
    componentMessages[lang] = allMessages[lang][component];
  });

  return { messages: componentMessages };
}
