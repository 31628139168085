<script>
import ContentCard from "@/components/designSystem/ContentCard.vue";

export default {
  name: "CardsThemeSection",
  components: { ContentCard },
  data: () => ({}),
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Cards</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="i in 4" :key="i" cols="3" align-self="center">
        <ContentCard
          :tags="[{ text: 'Tag Text' }]"
          title="Card Title"
          sub-title="Sub Title or description"
          :allow-saving="true"
          :show-button="true"
          image="https://cdn.sanity.io/images/ie8issds/yenza-production/1ae1ba1acf78ac933a00b38dc44bf28c35b753d9-4249x2722.jpg?h=550&q=50"
        >
          <template #chips>
            <v-chip small class="text-on-color--text font-weight-bold">
              Tags
            </v-chip>
          </template>
        </ContentCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
    </v-row>
  </v-container>
</template>

<style scoped></style>
