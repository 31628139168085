/**
 * Loads dynamic scripts based on certain configuration items
 */
function loadDynamicScripts(config) {
  if (config.oneTrustScriptID)
    loadOneTrustCookieBanner(config.oneTrustScriptID);

  if (config.typeFormEmbedID) loadTypeFormEmbedScript();
}

/**
 * Loads the One Trust Cookie Banner
 */
function loadOneTrustCookieBanner(id) {
  loadScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", {
    "data-domain-script": id,
  });
}

/**
 * Loads the Type Form Embed Script
 */
function loadTypeFormEmbedScript() {
  loadScript("https://embed.typeform.com/next/embed.js", {});
  loadStyles("https://embed.typeform.com/next/css/popup.css");
}

/**
 * Loads a custom script with provided data
 * @param url The URL of the custom script
 * @param attributes The additional data attributes for the script tag
 */
function loadScript(url, attributes) {
  const divScripts = document.getElementById("load-script");
  const newScript = document.createElement("script");

  for (const [key, value] of Object.entries(attributes)) {
    newScript.setAttribute(key, value);
  }

  newScript.src = url;
  divScripts.appendChild(newScript);
}

/**
 * Loads a custom stylesheet with provided data
 * @param url The URL of the custom stylesheet
 */
function loadStyles(url) {
  const divScripts = document.getElementById("load-script");
  const newScript = document.createElement("link");

  newScript.href = url;
  newScript.rel = "stylesheet";
  divScripts.appendChild(newScript);
}

export { loadDynamicScripts };
