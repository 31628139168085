<script>
export default {
  name: "TypographyThemeSection",
};
</script>

<template>
  <v-container id="chips" fluid>
    <v-row>
      <v-col>
        <h1>Text and Typography</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col><h3>Typography</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-left">
          <h1>Heading 1</h1>
          <br>
          <h3>Heading 2</h3>
          <br>
          <h3>Heading 3</h3>
          <br>
          <h4>Heading 4</h4>
          <br>
          <h5>Heading 5</h5>
          <br>
          <h6>Heading 6</h6>
          <br>
          <p>Paragraph</p>
          <br>
          <span>Span</span> <br>
          <div class="subtitle-1">
            Subtitle 1
          </div>
          <div class="subtitle-2">
            Subtitle 2
          </div>
          <div class="body-1">
            Body 1
          </div>
          <div class="body-2">
            Body 2
          </div>
          <div class="button">
            Button
          </div>
          <div class="caption">
            Caption
          </div>
          <div class="overline">
            Overline
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
